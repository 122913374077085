<template>
  <section class="mt-4">
    <b-table responsive="sm" :fields="fields" :items="items">
      <template #cell(ações)="">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <MoreIcon class="w-3 more__icon" />
          </template>
          <b-dropdown-item :to="`/groupPermission/edit/${1}`">
            <EditIcon class="w-3.5 mr-2 icon__actions" />
            <span class="align-middle ml-50">{{ $t("commons.toEdit") }}</span>
          </b-dropdown-item>

          <b-dropdown-item @click="modalIsOpen = true">
            <DeleteIcon class="w-3.5 mr-2 icon__actions" />
            <span class="align-middle ml-50">{{ $t("commons.toDelete") }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <footer class="mt-2 flex items-center">
      <Pagination
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :currentPage="currentPage"
        text="grupos"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen="modalIsOpen"
      :closeModal="closeModal"
      :buttonOptions="true"
      :modalText="$t('commons.modalTextDelete')"
      modalFeaturedText="Grupo permanente?"
    />
  </section>
</template>

<script>
import { BTable } from "bootstrap-vue";

import Modal from "@core/components/commons/modal/Modal.vue";
import Pagination from "@core/components/commons/pagination/Pagination.vue";

import MoreIcon from "@/assets/img/more.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
  name: "ListGroups",
  components: {
    Modal,
    Pagination,
    BTable,
    MoreIcon,
    EditIcon,
    DeleteIcon,
  },
  data() {
    return {
      modalIsOpen: false,
      totalItems: 500,
      itemsPerPage: 10,
      currentPage: 1,
      fields: [{ key: "grupo", sortable: true }, { key: "ações" }],
      items: [
        {
          grupo: "Administrador",
        },
        {
          grupo: "Colaborador",
        },
      ],
    };
  },
  methods: {
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
