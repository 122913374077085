import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListGroups from "@/components/groupPermission/ListGroups.vue";

export default {
  components: {
    HeaderRegister,
    ListGroups,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
